import { Switch, Route, withRouter } from "react-router-dom";
import pt from "prop-types";
import React, { Component } from "react";

import RootRoutes from "./Routes/RootRoutes";

export class Root extends Component {
  static propTypes = {
    history: pt.object,
  };

  constructor(props) {
    super(props);

    props.history.listen((_) => window.scrollTo(0, 0));
  }

  render() {
    return (
      <Switch>
        <Route path="/" component={RootRoutes} />
      </Switch>
    );
  }
}

export default withRouter(Root);

import React from "react";
import cn from "classnames";

export const addClassName = (child, classname) => {
  const className = cn(child.props.className, classname);
  return React.cloneElement(child, { className });
};

export const addClassNameIfNotIncludes = (
  child,
  classname,
  ifNotIncludedClassName
) => {
  const className = cn(child.props.className, {
    [classname]: child.props.className
      ? !child.props.className.includes(ifNotIncludedClassName)
      : true
  });

  return React.cloneElement(child, { className });
};

export const addChild = (child, newChild) => {
  const children = [].concat(child.props.children).concat(newChild);
  return React.cloneElement(child, { children });
};

export const addProps = (child, newProps) => {
  return React.cloneElement(child, { ...newProps });
};

import React, { Component } from "react";
import pt from "prop-types";
import cn from "classnames";
import { isIE } from "react-device-detect";
import { StickyContainer, Sticky } from "react-sticky";
import { Switch, Route } from "react-router-dom";

import Header from "../../components/Header";

import Home from "../../pages/Home";
import Embed from "../../pages/Embed";

export class RootRoutes extends Component {
  static propTypes = {
    history: pt.object,
  };

  renderHeader = () =>
    this.props.greaterThanContainerWidth ? (
      <div style={{ zIndex: 100 }}>
        <Sticky>
          {({ style, isSticky }) => (
            <div
              style={style}
              className={cn("floating-header", {
                "floating-header--applied": isSticky,
              })}
            >
              <Header />
            </div>
          )}
        </Sticky>
      </div>
    ) : (
      <Header />
    );

  render() {
    return (
      <StickyContainer
        className={cn(
          !this.props.history.location.pathname.includes("embed") &&
            ["page-container", "gray-background",
          {
            "page-container--ie": isIE,
          }]
        )}
      >
        {!this.props.history.location.pathname.includes("embed") &&
          this.renderHeader()}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/embed" component={Embed} />
        </Switch>
      </StickyContainer>
    );
  }
}

export default RootRoutes;

import React, { Component } from "react";

import Logo from "./Logo";
import MoreOptionsDropdown from "./MoreOptionsDropdown";
import Button from "../Button";

import styles from "./Header.module.scss";

class Header extends Component {
  render() {
    return (
      <>
        <div className={styles.header}>
          <Logo />
          <div className={styles.buttons}>
            <MoreOptionsDropdown showLogin />
            <div className={styles.ctaButtons}>
              <Button
                className={styles.button}
                type="primary"
                text="Mehr lesen"
                link="//fmp.rip/highlights/?utm_source=unforgotten.rip&utm_medium=header&utm_campaign=mehr-lesen"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Header;

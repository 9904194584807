import React from "react";
import { isSafari } from "react-device-detect";

import { LazyLoadImage } from "react-lazy-load-image-component";

import "react-lazy-load-image-component/src/effects/blur.css";

const Image = ({
  src,
  alt,
  className,
  effect = "blur",
  wrapperClassName = null,
}) => (
  <>
    {/* lazy load image component has terrible performance in Safari, so we fall bak to <img> in this case instead */}
    {isSafari ? (
      <span className={wrapperClassName}>
        <img className={className} alt={alt} src={src} />
      </span>
    ) : (
      <LazyLoadImage
        className={className}
        wrapperClassName={wrapperClassName}
        alt={alt}
        effect={effect}
        src={src}
      />
    )}
  </>
);

export default Image;

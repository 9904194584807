import React from "react";

import StackGrid from "react-stack-grid";

import Image from "../../components/Image";

import styles from "./Home.module.scss";

class Home extends React.Component {
  state = {
    stories: null,
  };

  componentDidMount() {
    this.getStories();
  }

  getStories = () => {
    const api = "https://app.funeral-market.place/backend/api/v2";

    fetch(
      api +
        "/stories?filter[category]=rip&filter[is_highlighted]=true&per_page=all&sort=-created_at",
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .catch((error) => console.log(error))
      .then((response) => {
        if (response.error) {
          console.log(response.reason);
          return;
        }
        this.setState({
          stories: response.data.filter((s) =>
            s.relationships.story_categories.data
              .map((c) => c.attributes.name)
              .includes("rip")
          ),
        });
      });
  };

  randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

  render() {
    const { stories } = this.state;

    if (!stories) {
      return <div />;
    }

    return (
      <StackGrid columnWidth={300} className={styles.grid}>
        {stories.map((s, i) => (
          <a
            className={styles.card}
            style={{
              marginBottom: this.randomInt(25, 60),
            }}
            href={`//fmp.rip/s/${s.id}/?utm_source=unforgotten.rip&utm_medium=rip-grid`}
            target="_blank"
            rel="noreferrer"
            key={s.id}
          >
            <Image
              src={
                s.relationships.cover_image.data?.attributes?.url +
                `?w=${Math.round(250 * window.devicePixelRatio)}&q=90`
              }
              className={styles.cardImage}
              key={i}
            />
            <div
              className={styles.cardTitle}
              dangerouslySetInnerHTML={{
                __html: s.attributes.title_short || s.attributes.title,
              }}
            />
            {/* <div dangerouslySetInnerHTML={{ __html: s.attributes.text }} /> */}
          </a>
        ))}
      </StackGrid>
    );
  }
}

export default Home;

import React from "react";
import pt from "prop-types";
import onClickOutside from "react-onclickoutside";
import cn from "classnames";

import { addClassNameIfNotIncludes, addChild } from "../../utils/modifyChild";

import styles from "./Dropdown.module.scss";

class Dropdown extends React.Component {
  static propTypes = {
    anchor: pt.object,
    className: pt.string,
    isShown: pt.bool,
    onClose: pt.func,
  };

  static defaultProps = {
    isShown: true,
  };

  handleClickOutside = () => this.props.onClose();

  render() {
    const { anchor, children, className, isShown } = this.props;

    return (
      <>
        {addChild(
          addClassNameIfNotIncludes(anchor, styles.container, "custom-option"),
          <div
            className={cn(
              styles.dropdown,
              "ignore-react-onclickoutside",
              className,
              { hidden: !isShown }
            )}
            key=""
          >
            <div className={styles.options}>
              {React.Children.map(children, (child) =>
                addClassNameIfNotIncludes(child, styles.option, "custom-option")
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default onClickOutside(Dropdown);

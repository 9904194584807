import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { addProps } from "../../utils/modifyChild";

import { IconLoadingAnimated } from "../Icons";

import styles from "./Button.module.scss";

const Button = ({
  text,
  link: _link,
  icon,
  type = "unstyled",
  onClick,
  children,
  disabled: _disabled = false,
  className: additionalStyles,
  iconClassName,
  minWidth = false,
  title = null,
  iconSize = 18,
  iconPosition = "left",
  isLoading = false,
  openInNewTab = false,
  style = {},
}) => {
  const disabled = _disabled || isLoading;

  const className = cn(
    styles.button,
    styles[type],
    {
      [styles.disabled]: disabled,
    },
    additionalStyles,
    { [styles.minWidth]: minWidth }
  );
  const link = _link && !disabled ? _link : null;
  const isExternal = !/^\/(?!\/)/.test(link || "");

  let flexDirection = "";
  let textMargin = {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    marginTop: 2,
  };
  switch (iconPosition) {
    case "right":
      flexDirection = "row-reverse";
      textMargin.marginRight = 10;
      break;
    case "top":
      flexDirection = "column";
      textMargin.marginTop = 10;
      break;
    case "bottom":
      flexDirection = "column-reverse";
      textMargin.marginBottom = 10;
      break;
    default:
      flexDirection = "row";
      textMargin.marginLeft = 10;
      break;
  }
  if (!icon) {
    textMargin = {};
  }

  const extraStyles = {
    display: "flex",
    flexDirection: flexDirection,
    alignItems: "center",
    pointerEvents: disabled ? "none" : "auto",
    ...style,
  };

  const body = (
    <>
      {isLoading && (
        <IconLoadingAnimated className={styles.loadingIcon} size={iconSize} />
      )}
      {icon && (
        <span
          className={cn(styles.icon, iconClassName)}
          style={{ width: iconSize, height: iconSize }}
        >
          {addProps(icon, { width: iconSize, height: iconSize })}
        </span>
      )}
      {text && (
        <span className={styles.title} style={textMargin}>
          {text}
        </span>
      )}
      {children}
    </>
  );
  return link ? (
    isExternal ? (
      <a
        className={className}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        title={title}
        style={extraStyles}
        disabled={disabled}
      >
        {body}
      </a>
    ) : (
      <Link
        to={link}
        className={className}
        title={title}
        style={extraStyles}
        disabled={disabled}
        target={openInNewTab ? "_blank" : ""}
        onClick={onClick && !disabled ? onClick : () => {}}
      >
        {body}
      </Link>
    )
  ) : (
    <div
      className={className}
      onClick={onClick && !disabled ? onClick : () => {}}
      title={title}
      style={extraStyles}
      disabled={disabled}
    >
      {body}
    </div>
  );
};

export default Button;

import React from "react";
import { NavLink } from "react-router-dom";

import MourningRibbon from "../../../img/images/mourning-ribbon.svg";
import Image from "../../Image";

import styles from "./Logo.module.scss";

class Logo extends React.Component {
  render() {
    return (
      <NavLink className={styles.logo} to="/">
        <Image
          src={MourningRibbon}
          alt="unforgotten.rip Logo"
          className={styles.image}
        />
        <div className={styles.text}>
          <div className={styles.name}>unforgotten.rip</div>
          <div className={styles.tagline}>gemeinsam erinnern</div>
        </div>
      </NavLink>
    );
  }
}

export default Logo;

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Helmet from "react-helmet";

import Root from "./Root";

import { isInDevelopmentMode } from "../constants/common";

export default function App() {
  return (
    <BrowserRouter
      getUserConfirmation={(message, callback) => {
        ReactDOM.render(document.getElementById("modal"));
      }}
    >
      {!isInDevelopmentMode && (
        <Helmet>
          <script
            async
            defer
            data-domain="unforgotten.rip"
            src="https://plausible.io/js/plausible.outbound-links.js"
          ></script>
        </Helmet>
      )}
      <Root />
    </BrowserRouter>
  );
}

import React from "react";

import Button from "../../Button";
import Dropdown from "../../Dropdown";

import { IconMore } from "../../Icons";

import styles from "./MoreOptionsDropdown.module.scss";

class MoreOptionsDropdown extends React.Component {
  state = {
    isShown: false,
  };

  links = [
    { label: "Kontakt", target: "mailto:office@funeral-market.place" },
    { label: "Impressum", target: "//fmp.rip/imprint" },
  ];

  render() {
    const { isShown } = this.state;

    return (
      <Dropdown
        isShown={isShown}
        className={styles.dropdown}
        onClose={() => this.setState({ isShown: false })}
        anchor={
          <Button
            icon={<IconMore />}
            type="round"
            onClick={() => this.setState({ isShown: !isShown })}
          />
        }
      >
        {this.links.map((link) => (
          <Button key={link.target} text={link.label} link={link.target} />
        ))}
      </Dropdown>
    );
  }
}

export default MoreOptionsDropdown;

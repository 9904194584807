import React from "react";
import pt from "prop-types";
import * as qs from "qs";

import Image from "../../components/Image";

import styles from "./Embed.module.scss";

class Embed extends React.Component {
  static propTypes = {
    history: pt.object,
  };

  state = {
    stories: null,
  };

  componentDidMount() {
    this.getStories();
  }

  getStories = () => {
    const { items } = qs.parse(this.props.history.location.search, {
      ignoreQueryPrefix: true,
    });

    const api = "https://app.funeral-market.place/backend/api/v2";

    fetch(
      api +
        `/stories?filter[category]=rip&filter[is_highlighted]=true&per_page=${
          items || "all"
        }&sort=-created_at`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .catch((error) => console.log(error))
      .then((response) => {
        if (response.error) {
          console.log(response.reason);
          return;
        }
        this.setState({
          stories: response.data.filter((s) =>
            s.relationships.story_categories.data
              .map((c) => c.attributes.name)
              .includes("rip")
          ),
        });
      });
  };

  randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

  render() {
    const { stories } = this.state;
    const { ref, utm_medium } = qs.parse(this.props.history.location.search, {
      ignoreQueryPrefix: true,
    });

    if (!stories) {
      return <div />;
    }

    return (
      <div className={styles.scroll}>
        {stories.map((s, i) => (
          <a
            className={styles.card}
            href={`//fmp.rip/s/${s.id}/?utm_source=${
              ref || "unforgotten.rip/embed"
            }&utm_campaign=unforgotten.rip${
              utm_medium ? `&utm_medium=${utm_medium}` : ""
            }`}
            target="_blank"
            rel="noreferrer"
            key={s.id}
          >
            <Image
              src={
                s.relationships.cover_image.data?.attributes?.url +
                `?w=${Math.round(250 * window.devicePixelRatio)}&q=90`
              }
              className={styles.cardImage}
              key={i}
            />
            <div
              className={styles.cardTitle}
              dangerouslySetInnerHTML={{
                __html: s.attributes.title_short || s.attributes.title,
              }}
            />
            {/* <div dangerouslySetInnerHTML={{ __html: s.attributes.text }} /> */}
          </a>
        ))}
      </div>
    );
  }
}

export default Embed;

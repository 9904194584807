import React from "react";
import cn from "classnames";

import styles from "./Icons.module.scss";

import { ReactComponent as IconAccount } from "../../img/icons/account.svg";
import { ReactComponent as IconCalendar } from "../../img/icons/calendar.svg";
import { ReactComponent as IconComments } from "../../img/icons/chat.svg";
import { ReactComponent as IconClipboard } from "../../img/icons/clipboard.svg";
import { ReactComponent as IconClose } from "../../img/icons/close.svg";
import { ReactComponent as IconEmbed } from "../../img/icons/code.svg";
import { ReactComponent as IconArrowDown } from "../../img/icons/down.svg";
import { ReactComponent as IconEdit } from "../../img/icons/edit.svg";
import { ReactComponent as IconMail } from "../../img/icons/email.svg";
import { ReactComponent as IconMailGlyph } from "../../img/icons/emailGlyph.svg";
import { ReactComponent as IconViews } from "../../img/icons/eye.svg";
import { ReactComponent as IconFacebook } from "../../img/icons/facebook.svg";
import { ReactComponent as IconFacebookAlt } from "../../img/icons/facebook-alt.svg";
import { ReactComponent as IconLikes } from "../../img/icons/heart.svg";
import { ReactComponent as IconInstagram } from "../../img/icons/instagram.svg";
import { ReactComponent as IconInstagramAlt } from "../../img/icons/instagram-alt.svg";
import { ReactComponent as IconLink } from "../../img/icons/link.svg";
import { ReactComponent as IconLinkGlyph } from "../../img/icons/linkGlyph.svg";
import { ReactComponent as IconLoading } from "../../img/icons/loading.svg";
import { ReactComponent as IconLogout } from "../../img/icons/logout.svg";
import { ReactComponent as IconMarker } from "../../img/icons/marker.svg";
import { ReactComponent as IconMarkers } from "../../img/icons/markers.svg";
import { ReactComponent as IconMore } from "../../img/icons/more.svg";
import { ReactComponent as IconPasswordHidden } from "../../img/icons/password-hidden.svg";
import { ReactComponent as IconPasswordShown } from "../../img/icons/password-shown.svg";
import { ReactComponent as IconPinterest } from "../../img/icons/pinterest.svg";
import { ReactComponent as IconPinterestAlt } from "../../img/icons/pinterest-alt.svg";
import { ReactComponent as IconSpace } from "../../img/icons/planet.svg";
import { ReactComponent as IconAdd } from "../../img/icons/plus.svg";
import { ReactComponent as IconPopoverAnchor } from "../../img/icons/popover-anchor.svg";
import { ReactComponent as IconArrowRight } from "../../img/icons/right.svg";
import { ReactComponent as IconSearch } from "../../img/icons/search.svg";
import { ReactComponent as IconShare } from "../../img/icons/share.svg";
import { ReactComponent as IconTrash } from "../../img/icons/trash.svg";
import { ReactComponent as IconTwitter } from "../../img/icons/twitter.svg";
import { ReactComponent as IconTwitterAlt } from "../../img/icons/twitter-alt.svg";
import { ReactComponent as IconUpload } from "../../img/icons/upload.svg";

const IconLoadingAnimated = ({ className, size }) => (
  <IconLoading
    className={cn(styles.loading, className)}
    style={{ width: size, height: size }}
  />
);

export {
  IconAdd,
  IconAccount,
  IconCalendar,
  IconComments,
  IconClipboard,
  IconClose,
  IconEdit,
  IconEmbed,
  IconArrowDown,
  IconMail,
  IconMailGlyph,
  IconViews,
  IconFacebook,
  IconFacebookAlt,
  IconLikes,
  IconLoadingAnimated,
  IconInstagram,
  IconInstagramAlt,
  IconLink,
  IconLinkGlyph,
  IconLogout,
  IconMarker,
  IconMarkers,
  IconMore,
  IconPasswordHidden,
  IconPasswordShown,
  IconPinterest,
  IconPinterestAlt,
  IconSpace,
  IconPopoverAnchor,
  IconArrowRight,
  IconSearch,
  IconShare,
  IconTrash,
  IconTwitter,
  IconTwitterAlt,
  IconUpload,
};
